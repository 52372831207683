









































import HomeComponent from "@/components/homecomponent/HomeComponent";
export default HomeComponent;
